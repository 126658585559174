<template>
  <div id="users">
    <div class="subheader pb-2 pb-lg-4 subheader-transparent" id="kt_subheader">
      <div
        class="
              container-fluid
              d-flex
              align-items-center
              justify-content-between
              flex-wrap flex-sm-nowrap
            "
      >
        <!--begin::Details-->
        <div class="d-flex align-items-center flex-wrap mr-2">
          <!--begin::Title-->
          <h5
            class="text-dark font-weight-bold mt-2 mb-2 mr-5"
            v-if="!byDepartment"
          >
            {{ $t("menu.controller.users.title") }}
          </h5>
          <h5 v-else>
            {{
              $t("menu.controller.users.byDepartment", {
                department: searchDepartment
              })
            }}
          </h5>
          <!--end::Title-->
          <!--begin::Separator-->
          <div
            class="
                  subheader-separator subheader-separator-ver
                  mt-2
                  mb-2
                  mr-5
                  bg-gray-200
                "
          ></div>
          <!--end::Separator-->
          <!--begin::Search Form-->
          <div class="d-flex align-items-center" id="kt_subheader_search">
            <span class="text-dark-50 font-weight-bold" id="kt_subheader_total"
              >{{ total }}
              {{
                $t("tables.total", {
                  module: $tc("tables.user", total).toLowerCase()
                })
              }}</span
            >
            <div class="ml-5">
              <div
                class="input-group input-group-sm input-group-solid"
                style="max-width: 175px"
              >
                <input
                  type="text"
                  class="form-control"
                  id="search"
                  v-model="search"
                  :placeholder="$t('tables.search') + ' ...'"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <span class="svg-icon">
                      <!--begin::Svg Icon | path:/metronic/theme/html/demo9/dist/assets/media/svg/icons/General/Search.svg-->
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M14.293 16.707a1 1 0 011.414-1.414l4 4a1 1 0 01-1.414 1.414l-4-4z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M11 16a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 110-14 7 7 0 010 14z" fill="#000" fill-rule="nonzero"/></g></svg>
                      <!--end::Svg Icon-->
                    </span>
                    <!--<i class="flaticon2-search-1 icon-sm"></i>-->
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!--end::Search Form-->
          <div class="d-flex align-items-center ml-5">
            <!--begin::Profile filter-->
            <b-dropdown
              id="ddown-types"
              :text="$t(`assets.titles.profiles`)"
              class="mr-6"
            >
              <div
                v-for="(element, index) in profilesTypes"
                :key="element.text + index"
                class="dropdown-item bg-white text-body"
              >
                <b-form-checkbox
                  @change="filter(element, 'profilesTypes')"
                  :value="element.value"
                  >{{ element.text }}</b-form-checkbox
                >
              </div>
            </b-dropdown>
            <b-button
              @click="updateMultiUser()"
              variant="light-success"
              class="ml-2 font-weight-bold"
              :disabled="selected.length == 0 && total == 0"
            >
              <i class="la la-pencil pl-1" />
              {{
                $t("assets.buttons.update_users", {
                  users: selected.length == 0 ? total : selected.length
                })
              }}
            </b-button>
            <b-button
              @click="deleteMultiUser()"
              variant="light-danger"
              class="ml-2 font-weight-bold"
              :disabled="selected.length == 0 && total == 0"
            >
              <i class="la la-trash pl-1" />
              {{
                $t("assets.buttons.delete_users", {
                  users: selected.length == 0 ? total : selected.length
                })
              }}
            </b-button>
            <!--end::Profile filter-->
          </div>
        </div>
        <!--end::Details-->
        <!--begin::Toolbar-->
        <div class="d-flex align-items-center">
          <b-button
            @click="exportUsersExcel"
            :disabled="loadingExcel"
            v-b-tooltip.bottom.hover
            :title="$t('tables.download')"
            variant="light-primary"
            class="ml-2 font-weight-bold"
          >
            <span class="svg-icon svg-icon-light-primary p-0">
              <simple-svg
                v-if="!loadingExcel"
                :src="require('@/assets/images/icons/downloaded-file.svg')"
              />
              <hollow-dots-spinner
                v-if="loadingExcel"
                :animation-duration="2000"
                :dot-size="6"
                :dots-num="3"
                :color="'#0a2f24'"
              />
            </span>
          </b-button>

          <b-button
            @click="sendWellcomeMail"
            variant="light-primary"
            class="ml-2 font-weight-bold"
            v-b-tooltip.bottom.hover
            :disabled="selected.length == 0 && total == 0"
            :title="$tc('tables.sendWellcomeMail')"
          >
            {{ selected.length == 0 ? total : selected.length }}
            <i class="p-0 fa fa-envelope" />
          </b-button>

          <b-button
            @click="$router.push({ name: 'UserCreate' })"
            variant="light-success"
            class="ml-2 font-weight-bold"
            v-b-tooltip.bottom.hover
            :title="$t('tables.create.employee')"
          >
            <i class="p-0 fa fa-plus" />
          </b-button>
          <input
            name="excelFile"
            ref="excelFile"
            id="excelFile"
            style="display:none;"
            type="file"
            accept=".xls,.xlsx"
            @change="handleFileExcel"
          />
          <label
            style="margin-bottom: 0px;"
            :title="$t('admin.excel.title.import_excel')"
            for="excelFile"
            class="btn ml-2 font-weight-bold btn-light-success"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-filetype-xls"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM6.472 15.29a1.2 1.2 0 0 1-.111-.449h.765a.58.58 0 0 0 .254.384q.106.073.25.114.143.041.319.041.246 0 .413-.07a.56.56 0 0 0 .255-.193.5.5 0 0 0 .085-.29.39.39 0 0 0-.153-.326q-.152-.12-.462-.193l-.619-.143a1.7 1.7 0 0 1-.539-.214 1 1 0 0 1-.351-.367 1.1 1.1 0 0 1-.123-.524q0-.366.19-.639.19-.272.527-.422.338-.15.777-.149.457 0 .78.152.324.153.5.41.18.255.2.566h-.75a.56.56 0 0 0-.12-.258.6.6 0 0 0-.247-.181.9.9 0 0 0-.369-.068q-.325 0-.513.152a.47.47 0 0 0-.184.384q0 .18.143.3a1 1 0 0 0 .405.175l.62.143q.326.075.566.211a1 1 0 0 1 .375.358q.135.222.135.56 0 .37-.188.656a1.2 1.2 0 0 1-.539.439q-.351.158-.858.158-.381 0-.665-.09a1.4 1.4 0 0 1-.478-.252 1.1 1.1 0 0 1-.29-.375m-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94zm2.727 3.325H4.557v-3.325h-.79v4h2.487z"
              />
            </svg>
          </label>
          <a
            :title="$t('admin.excel.title.download_template')"
            class="btn ml-2 font-weight-bold btn-light-info"
            href="/excels/Template_Upload_Users_Kymatio.xlsx"
            download
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-file-excel"
              viewBox="0 0 16 16"
            >
              <path
                d="M5.18 4.616a.5.5 0 0 1 .704.064L8 7.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 8l2.233 2.68a.5.5 0 0 1-.768.64L8 8.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 8 5.116 5.32a.5.5 0 0 1 .064-.704"
              />
              <path
                d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
    <b-card
      class="card-custom card-stretch card-rounded"
      no-body
      v-if="areUsersLoaded"
    >
      <b-card-body>
        <b-table
          id="users-table"
          hover
          :items="filtered"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          primary-key="stakeholderId"
          responsive="sm"
          stacked="sm"
          show-empty
          selectable
          @row-selected="onRowSelected"
          ref="selectableTable"
        >
          <template>
            <empty-search
              :description="$t(`tables.empty.description`)"
              :buttonText="$t(`tables.empty.button`)"
              :action="resetFilters"
            />
          </template>
          <template #cell(selected)="{ rowSelected }">
            <i v-if="rowSelected" class="fa fa-check" />
          </template>
          <template #cell(profiles)="row">
            {{ getUserProfiles(row.item) }}
          </template>
          <template #cell(actions)="row">
            <b-button
              pill
              variant="light-primary"
              class="mr-2 px-auto"
              @click="updateUser(row.item.stakeholderId)"
            >
              <i class="la la-pencil pl-1" />
            </b-button>
            <b-button
              pill
              variant="light-danger"
              class="mr-2 px-auto"
              @click="deleteUser(row.item)"
            >
              <i class="la la-trash pl-1" />
            </b-button>
          </template>
        </b-table>
        <div class="flex-wrap mx-auto" v-if="total > 0">
          <b-pagination
            class="d-flex flex-wrap mt-3 py-2 mr-3"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="users-table"
            first-number
            pill
            last-number
            align="center"
            size="lg"
          >
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import {
  deleteStakeholder,
  deleteStakeholders,
  sendWellcomeMail
} from "@/api/admin/stakeholders.api";

import EmptySearch from "@/components/EmptySearch.component.vue";
import { SimpleSVG } from "vue-simple-svg";
import readXlsxFile from "read-excel-file";
import { HollowDotsSpinner } from "epic-spinners";
import * as XLSX from "xlsx";

export default {
  name: "UsersList",
  components: {
    EmptySearch,
    "simple-svg": SimpleSVG,
    HollowDotsSpinner
  },
  data() {
    return {
      perPage: 10,
      grid: true,
      currentPage: 1,
      responseCount: 0,
      totalElements: 0,
      search: "",
      byDepartment: false,
      searchDepartment: "",
      profilesTypes: [
        { text: this.$t("menu.toggle.filter.employee"), value: "USER" },
        { text: this.$t("menu.toggle.filter.controller"), value: "CONTROLLER" },
        { text: this.$t("menu.toggle.filter.admin"), value: "ADMIN" }
      ],
      activeFilters: {
        profilesTypes: []
      },
      allSelected: false,
      selected: [],
      loadingExcel: false,
      isSubmitting: false
    };
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    ...mapGetters("Admin", ["areUsersLoaded", "users", "companySelected"]),
    ...mapGetters("Auth", ["hasModule"]),
    progress() {
      return (this.responseCount / this.totalElements || 0) * 100;
    },
    fields() {
      let fields = [
        {
          key: "selected",
          label: this.$tc("tables.selected", 1),
          sortable: false,
          class: "align-middle"
        },
        {
          key: "name",
          label: this.$tc("tables.name", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left",
          formatter: (val, key, item) => {
            return item.name + " " + item.surname;
          }
        },
        {
          key: "department",
          label: this.$tc("tables.department", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left",
          formatter: (val, key, item) => {
            return item.stakeholderDepartmentName;
          }
        },
        {
          key: "email",
          label: this.$tc("tables.email", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left"
        },
        {
          key: "profiles",
          label: this.$tc("tables.profiles", 1),
          sortable: false,
          sortByFormatted: true,
          class: "align-middle text-left"
        },
        {
          key: "actions",
          label: this.$t("tables.actions"),
          sortable: false,
          class: "align-middle"
        }
      ];
      return _.filter(fields, item => {
        return item.service === undefined || this.hasModule([item.service]);
      });
    },
    excelFields() {
      var fields = {};
      fields[this.$tc("tables.name", 1)] = {
        name: "name",
        callback: value => value.name
      };
      fields[this.$tc("tables.surname", 1)] = {
        name: "surname",
        callback: value => value.surname
      };
      fields[this.$tc("tables.email")] = {
        name: "email",
        callback: value => value.email
      };
      fields[this.$tc("tables.department", 1)] = {
        name: "department",
        callback: value => value.stakeholderDepartmentName
      };
      return fields;
    },
    total() {
      let users = this.users;
      if (this.activeFilters.profilesTypes.length > 0) {
        users = _.filter(users, user => {
          return Object.keys(user.profiles).some(profile =>
            this.activeFilters.profilesTypes.includes(profile)
          );
        });
      }
      if (this.byDepartment !== false) {
        users = _.filter(users, user => {
          if (user.stakeholderDepartmentId === this.$route.query.department) {
            this.searchDepartment = user.stakeholderDepartmentName;
            return true;
          } else {
            return false;
          }
        });
      }
      if (this.search !== "") {
        const searchTerms = this.search
          .split(";")
          .filter(term => term != "")
          .map(term => term.trim().toLowerCase());

        // Filter the users array
        users = users.filter(user => {
          // Check if any of the search terms match any of the user's properties
          return searchTerms.some(
            term =>
              user.email.toLowerCase().includes(term) ||
              user.stakeholderDepartmentName?.toLowerCase().includes(term) ||
              (user.name + user.surname).toLowerCase().includes(term)
          );
        });
      }
      return _.size(users);
    },
    filtered() {
      let users = this.users;
      if (this.activeFilters.profilesTypes.length > 0) {
        users = _.filter(users, user => {
          return Object.keys(user.profiles).some(profile =>
            this.activeFilters.profilesTypes.includes(profile)
          );
        });
      }
      if (this.byDepartment !== false) {
        users = _.filter(users, user => {
          if (user.stakeholderDepartmentId === this.$route.query.department) {
            this.searchDepartment = user.stakeholderDepartmentName;
            return true;
          } else {
            return false;
          }
        });
      }
      if (this.search !== "") {
        const searchTerms = this.search
          .split(";")
          .filter(term => term != "")
          .map(term => term.trim().toLowerCase());

        // Filter the users array
        users = users.filter(user => {
          // Check if any of the search terms match any of the user's properties
          return searchTerms.some(
            term =>
              user.email.toLowerCase().includes(term) ||
              user.stakeholderDepartmentName?.toLowerCase().includes(term) ||
              (user.name + user.surname).toLowerCase().includes(term)
          );
        });
      }

      return this.grid ? _.slice(users, 0, this.showing) : users;
    },
    excelUsers() {
      return _.map(this.filtered, user => {
        return [
          user.name,
          user.surname,
          user.stakeholderDepartmentName,
          user.isBoss ? "YES" : "NO",
          user.tags
            ? _.first(user.tags[this.getConstant("TAG_PEOPLEFUNCTION")])
            : "",
          user.email,
          user.login,
          this.getAuthenticationMethod(user.authentication),
          _.includes(user.profiles.ADMIN, this.companySelected.stakeholderId)
            ? "YES"
            : "NO",
          _.includes(
            user.profiles.CONTROLLER,
            this.companySelected.stakeholderId
          )
            ? "YES"
            : "NO",
          _.includes(user.profiles.USER, this.companySelected.stakeholderId)
            ? "YES"
            : "NO",
          user.environment?.timezone,
          user.environment?.languages?.default,
          user.phoneNumber
        ];
      });
    }
  },
  methods: {
    ...mapActions("Admin", [
      "loadCompanySelected",
      "loadDepartments",
      "reset",
      "loadUsers"
    ]),
    getAuthenticationMethod(method) {
      let result = "PASSWORD";
      switch (method) {
        case this.getConstant("AUTHENTICATION_TOTP_PASSWORD"):
          result = "2FA";
          break;
        case this.getConstant("AUTHENTICATION_FEDERATION"):
          result = "FEDERATION";
          break;
        case this.getConstant("AUTHENTICATION_NOAUTH"):
          result = "NO_AUTH";
          break;
      }
      return result;
    },
    exportUsersExcel() {
      this.loadingExcel = true;
      const worksheet = XLSX.utils.json_to_sheet(this.excelUsers);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "2.-Empleados (internal)"
      );
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            "Name",
            "Surname",
            "Department",
            "isManager",
            "Position",
            "User email",
            "Login",
            "Authentication",
            "Perfil - Administrador",
            "Perfil - Operador",
            "Perfil - Usuario",
            "Time Zone",
            "Locale",
            "User Phone"
          ]
        ],
        { origin: "A1" }
      );
      XLSX.writeFile(workbook, this.companySelected.name + "_Users.xlsx");
      this.loadingExcel = false;
    },
    handleFileExcel(event) {
      const requiredColumnNames = [
        "Name",
        "Surname",
        "Department",
        "isManager",
        "Position",
        "User email",
        "Perfil - Administrador",
        "Perfil - Operador",
        "Perfil - Usuario",
        "Time Zone",
        "Locale",
        "User Phone",
        "Login",
        "Authentication"
      ];
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile, { sheet: "2.-Empleados (internal)" })
        .then(rows => {
          const columnNames = rows[0];
          const missingHeaders = _.difference(requiredColumnNames, columnNames);
          if (missingHeaders.length > 0) {
            this.$swal({
              title: "Error",
              text: "Faltan encabezados: " + missingHeaders,
              icon: "error",
              button: "OK"
            }).then(() => {
              this.$refs.excelFile.value = null;
            });
          } else {
            const jsonData = rows
              .slice(1)
              .map((row, index) => {
                const rowData = {};
                if (!row.every(element => element === null)) {
                  columnNames.forEach((columnName, i) => {
                    rowData[columnName] = row[i];
                  });
                  rowData["rowId"] = index + 1;
                }
                return rowData;
              })
              .filter(row => Object.keys(row).length > 1);

            _.each(jsonData, (data, index) => {
              data["rowId"] = index;
            });

            this.$router.push({
              name: "UsersExcel",
              params: {
                excelData: jsonData
              }
            });
          }
        })
        .catch(error => {
          this.$swal({
            title: "Error",
            text: error,
            icon: "error",
            button: "OK"
          }).then(() => {
            this.$refs.excelFile.value = null;
          });
        });
    },
    sendWellcomeMail() {
      let users = this.selected.length == 0 ? this.filtered : this.selected;
      this.$swal({
        html:
          this.$t("admin.popup.send_wellcome_mail.question", {
            users: users.length
          }) +
          "<br>" +
          "<br>" +
          this.$t("admin.popup.send_wellcome_mail.sendConfirm", {
            word: this.$t("admin.popup.send_wellcome_mail.answer")
          }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0BB783",
        confirmButtonText: this.$t("admin.popup.confirmButton"),
        cancelButtonText: this.$t("admin.popup.cancelButton"),
        input: "text",
        inputValidator: value => {
          if (value === this.$t("admin.popup.send_wellcome_mail.answer")) {
            let stakeholdersId = _.map(users, element => element.stakeholderId);
            sendWellcomeMail(
              localStorage.getItem("managedCompanyId"),
              stakeholdersId
            ).then(response => {
              this.$root.$bvToast.toast(
                this.$t("admin.form.success.send_wellcome_mail_body", {
                  users: response.data.records.length
                }),
                {
                  title: this.$t(
                    "admin.form.success.send_wellcome_mail_header"
                  ),
                  variant: "success",
                  solid: true,
                  toaster: "b-toaster-top-center"
                }
              );
            });
          } else {
            return this.$t("admin.popup.incorrectWord");
          }
        }
      });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    filter(id, type) {
      _.includes(this.activeFilters[type], id.value)
        ? (this.activeFilters[type] = _.reject(
            this.activeFilters[type],
            el => el === id.value
          ))
        : this.activeFilters[type].push(id.value);
    },
    updateUser(stakeholderId) {
      this.$router.push({
        name: "UserUpdate",
        query: { type: "single" },
        params: {
          id: stakeholderId
        }
      });
    },
    updateMultiUser() {
      let users = this.selected.length == 0 ? this.filtered : this.selected;
      this.$router.push({
        name: "UserUpdate",
        query: { type: "multiple" },
        params: {
          users: _.map(users, element => element.stakeholderId)
        }
      });
    },
    deleteMultiUser() {
      let users = this.selected.length == 0 ? this.filtered : this.selected;

      this.$swal({
        html: this.$t("admin.popup.delete.multi_user", {
          users: users.length,
          confirmation: this.$t("admin.popup.delete.delete_confirmation")
        }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0BB783",
        confirmButtonText: this.$t("admin.popup.confirmButton"),
        cancelButtonText: this.$t("admin.popup.cancelButton"),
        input: "text",
        inputValidator: value => {
          if (value === this.$t("admin.popup.delete.delete_confirmation")) {
            let stakeholdersIds = _.map(
              users,
              element => element.stakeholderId
            );
            let stakeholdersBatches = [];

            this.isSubmitting = true;
            this.errors = [];
            this.responseCount = 0;
            this.totalElements = 0;

            var pager = 0;
            var limit = 10;
            while (pager * limit < _.size(stakeholdersIds)) {
              stakeholdersBatches.push(
                _.slice(stakeholdersIds, pager * limit, (pager + 1) * limit)
              );
              pager++;
            }

            this.totalElements = _.size(stakeholdersBatches);
            if (this.totalElements > 0) {
              this.sendDataInBatches(stakeholdersBatches);
            }
          } else {
            this.isSubmitting = false;
            return this.$t("admin.popup.incorrectWord");
          }
        }
      });
    },
    sendDataInBatches(data) {
      this.responseCount = 0;

      const sendData = stakeholder => {
        return deleteStakeholders("people", stakeholder)
          .then(response => {
            this.responseCount++;
            // Function to update progress bar
            document.getElementById(
              "progress-fill"
            ).style.width = `${this.progress}%`;

            if (response.status === 200) {
              this.correct.push(...data.stakeholdersId);
            } else {
              this.incorrect.push(...data.stakeholdersId);
            }

            return response;
          })
          .catch(error => {
            return error.response;
          });
      };

      this.$swal.close(); // Ensure no previous Swal is open

      this.swal = this.$swal({
        title: "Processing...",
        html:
          '<div id="progress-bar" style="width: 100%; background: #eee;"><div id="progress-fill" style="width: 0%; height: 10px; background: #28a745;"></div></div>',
        allowOutsideClick: false,
        showConfirmButton: false
      });

      let requestsLimit = 5;
      let allResponses = [];

      const sendAndProcessBatch = async batch => {
        const batchRequests = batch.map(row => {
          return sendData(row);
        });

        const batchResponses = await Promise.all(batchRequests);
        allResponses.push(...batchResponses);

        if (allResponses.length === data.length) {
          _.each(allResponses, response => {
            if (response.data._meta.status === "ERROR") {
              this.$swal("Error", response.data.records, "error");
            }
          });
        }
      };

      const processAllBatches = async () => {
        const batches = _.chunk(data, requestsLimit);
        for (const batch of batches) {
          await sendAndProcessBatch(batch);
        }
      };

      processAllBatches()
        .then(() => {
          this.isSubmitting = false;
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    },
    deleteUser(stakeholder) {
      this.$swal({
        html:
          this.$t("admin.popup.delete.user") +
          "<br>" +
          stakeholder.name +
          " " +
          stakeholder.surname,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0BB783",
        confirmButtonText: this.$t("admin.popup.confirmButton"),
        cancelButtonText: this.$t("admin.popup.cancelButton")
      }).then(result => {
        if (result.isConfirmed) {
          deleteStakeholder("people", stakeholder.stakeholderId).then(() => {
            this.loadCompanySelected();
            this.loadDepartments();
            this.loadUsers();
          });
        }
      });
    },
    resetFilters() {
      this.search = "";
    },
    getUserProfiles(item) {
      let profiles = [];
      _.each(Object.keys(item.profiles), profile => {
        if (
          item.profiles[profile].includes(
            Number(localStorage.getItem("managedCompanyId"))
          )
        ) {
          _.isEqual(profile.toLowerCase(), "controller")
            ? profiles.push("O")
            : profiles.push(profile.slice(0, 1));
        }
      });

      return profiles.join("/");
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.areUsersLoaded) this.loadUsers();
    if (this.$route.query.department) this.byDepartment = true;
  },
  watch: {
    responseCount(value) {
      if (value >= this.totalElements) {
        setTimeout(() => {
          this.loadCompanySelected();
          this.loadDepartments();
          this.loadUsers();
          this.swal?.close();
        }, 3000);
      }
    },
    isConstantsActive(value) {
      if (value === true) this.loadUsers();
    },
    areUsersLoaded(value) {
      if (this.isConstantsActive && value === false) this.loadUsers();
    }
  }
};
</script>
